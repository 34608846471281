import React, {FormEvent, useEffect, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {VStore} from "../../store";
import './_login-modal.scss';
import {FirebaseUtils} from "../../utils/firebaseUtils";

export default function LoginModal() {
    const {isShowLogin, setIsShowLogin, setIsShowRegister, setCurrentUser} = VStore();
    const [input, setInput] = useState({
        email: '',
        password: ''
    });

    const [error, setError] = useState('');

    function handleClose() {
        setInput({email: '', password: ''});
        setError('');
        setIsShowLogin(false);
    }

    function handleShowRegister() {
        setIsShowLogin(false);
        setIsShowRegister(true);
    }

    async function onLogin(e: FormEvent) {
        e.preventDefault();
        try {
            const result = await FirebaseUtils.signIn(input.email, input.password);
            if (!result) {
                return;
            }
            const {uid} = result.user;
            const userData = await FirebaseUtils.getUserData(uid);
            if (userData) {
                setCurrentUser({
                    ...userData,
                    uid
                });
                handleClose();
            }
        } catch (e: any) {
            if (e.code === 'auth/invalid-credential') {
                setError('Invalid email or password');
            }
            return;
        }
    }

    useEffect(() => {
        setError('');
    }, [input]);

    useEffect(() => {
        FirebaseUtils.onAuthStateChanged( async (result) => {
            if (!result) {
                return;
            } else {
                setCurrentUser(null);
            }
            const {uid} = result;
            const userData = await FirebaseUtils.getUserData(uid);
            if (userData) {
                setCurrentUser({
                    ...userData,
                    uid
                });
                handleClose();
            }
        });
    }, []);

    return <Modal show={isShowLogin}
                  onHide={() => handleClose()}
                  backdrop='static'
                  keyboard={false}
                  centered={true}
                  className='login-modal'
    >
        <Modal.Header closeButton={true}>
            <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={onLogin}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type='email' placeholder='Enter email' required={true}
                                  onChange={(e) => setInput({...input, email: e.target.value})} value={input.email}
                    />
                </Form.Group>
                <Form.Group className='mt-3'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control type='password' placeholder='Enter password' required={true}
                                  onChange={(e) => setInput({...input, password: e.target.value})}
                                  value={input.password}
                    ></Form.Control>
                </Form.Group>
                {
                    error && <Form.Group className='mt-3'>
                        <span style={{color: 'red'}}>{error}</span>
                    </Form.Group>
                }
                <div className='w-100 mt-3 d-flex justify-content-between'>
                    <Form.Group>
                        <Button variant='success' className='me-3' type='submit'>Login</Button>
                        <Button variant='primary' onClick={handleShowRegister}>Register</Button>
                    </Form.Group>
                    <Form.Group>
                        <Button variant='secondary' onClick={handleClose}>Close</Button>
                    </Form.Group>
                </div>
            </Form>
        </Modal.Body>
    </Modal>;
}
