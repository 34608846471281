import './_3d-app.scss';
import {useEffect, useRef, useState} from "react";
import puzzles from "../../utils/puzzles";
import {VStore} from "../../store";

const V3DApp = () => {

    const {setCurrentPart, data, textures, materials, shoe, currentSide, setDefaultShoe, shoeType} = VStore();

    const [isLoaded, setIsLoaded] = useState(false);

    const iframeRef = useRef(null);

    useEffect(() => {
        function handleLoaded(e: MessageEvent) {
            if (e.data === 'v3d_loaded' && iframeRef.current) {
                //@ts-ignore
                puzzles.setProcedures(iframeRef.current.contentWindow.v3d.puzzles.procedures);
                //@ts-ignore
                iframeRef.current.focus();
                setIsLoaded(true);
                setDefaultShoe();
                if (shoeType === 'crazy_lab') {
                    puzzles.procedures?.switchToCrazyLab();
                }
            } else if (e.data.type === 'select-object' && currentSide !== 'pair') {
                setCurrentPart(e.data.object.replace('.left', ''));
            }
        }

        window.addEventListener('message', handleLoaded)

        return () => {
            window.removeEventListener('message', handleLoaded)
        }
    }, []);


    useEffect(() => {
        if (Object.keys(data).length && Object.keys(textures) && Object.keys(materials) && isLoaded) {
            Object.entries(shoe.left).forEach(([key, value]) => {
                const texture = textures[value];
                if (texture) {
                    const material = materials[texture.materialId];
                    if (material) {
                        if (material.type === 'mirror') {
                            puzzles.procedures?.setGlossyLeather(key, texture.color!)
                        } else if (material.type === 'meta') {
                            puzzles.procedures?.setMetallicLeather(key, texture.color!)
                        } else if (material.type === 'matt') {
                            puzzles.procedures?.setMattLeather(key, texture.color!)
                        }
                    }
                }
            })
        }
    }, [data, textures, isLoaded, materials]);

    return <div className='v3d-viewer'>
        <iframe src="./assets/3d-model/cityrun_SKLZ"
                title='3d-viewer'
                ref={iframeRef}>
        </iframe>

    </div>
}

export default V3DApp;
