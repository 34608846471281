import React, {useState} from 'react';
import './_menu-bar.scss';
import {VStore} from "../../store";
import {FirebaseUtils} from "../../utils/firebaseUtils";
import {Button, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function MenuBar() {
    const {setIsShowLogin, currentUser, setCurrentUser} = VStore();
    const [confirmSignOut, setConfirmSignOut] = useState(false);

    function handleAuth() {
        if (!currentUser) {
            setIsShowLogin(true);
        }
    }

    async function handleSignOut() {
        await FirebaseUtils.signOut();
        setCurrentUser(null);
        setConfirmSignOut(false);
    }

    return (<div className="menu-bar d-flex justify-content-center w-100 pt-1">
        <div className='col d-flex justify-content-center align-items-center'>
            <div className='col-3 col-sm-2 h-100 d-flex align-items-center home'>
                <Link to={'/'}>
                    <img src="./assets/images/icons/logo.jpg" alt=""/>
                </Link>
            </div>
            <div className='menu-bar-items h-100'>
                <ul>
                    <li>
                        <span>Inline</span>
                        <img src="./assets/images/icons/down.svg" alt=""/>
                    </li>
                    <li>
                        <span>Short</span>
                        <img src="./assets/images/icons/down.svg" alt=""/>
                    </li>
                    <li>
                        <span>Long</span>
                        <img src="./assets/images/icons/down.svg" alt=""/>
                    </li>
                </ul>
            </div>
            <div className={'user-auth' + (currentUser ? ' logged' : '')}>
                <div>
                    <div className='user-auth__icon' onClick={handleAuth}>
                        {
                            currentUser ? <span>{currentUser.firstName[0] + currentUser.lastName[0]}</span> :
                                <img src="./assets/images/icons/user.svg" alt="User"/>
                        }
                    </div>
                    <div className='user-auth__signout' onClick={() => setConfirmSignOut(true)}>
                        <span>Sign Out</span>
                    </div>
                </div>
            </div>
        </div>

        <Modal show={confirmSignOut}
               centered={true}
               size={'sm'}
               onHide={() => setConfirmSignOut(false)}
        >
            <Modal.Header closeButton={true}>
            </Modal.Header>
            <Modal.Body>
                <span>Do you want to Sign out?</span>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex'>
                    <Button size='sm' variant='success' className='me-3' onClick={handleSignOut}>Yes</Button>
                    <Button size='sm' variant='secondary' onClick={() => setConfirmSignOut(false)}>No</Button>
                </div>
            </Modal.Footer>
        </Modal>
    </div>);
}
